<template lang="pug">
.booking-ticket-card
  .card.booking-ticket-card.h-100
    .card-header.align-items-center
      .float-left
        h5.font-weight-bold Anfrage: {{ bookingTicket.Id }}
      .float-right
        h5.font-weight-bold {{ $t("bookingTicketStatus." + bookingTicket.BookingTicketStatusId) }}
    .card-body
      booking-ticket-overview(
        :booking-ticket="bookingTicket"
        :is-edit="isEdit"
        :load-booking-ticket="loadBookingTicket"
      )

</template>

<script>
import BookingTicketOverview from "@/views/bookingTickets/BookingTicketOverview.vue";

export default {
  name: "BookingTicketCard",
  components: { BookingTicketOverview },
  props: {
    bookingTicket: {
      type: Object,
      required: true,
    },
    isEdit: {
      type: Boolean,
      required: false,
      default: false,
    },
    loadBookingTicket: {
      type: Function,
      required: false,
    },
  },
};
</script>

<style scoped></style>
